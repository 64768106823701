/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/index'
import { lsGetLogInfo } from '@/plugins/lsstore' // delete later *********************
import Store from '@/store'

Vue.use(VueRouter)
const routes = [
	// todo   -------------------------- $ no guards routes -------------------------------------
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/',
		name: 'Home',
		meta: { requiresAuth: true },
		component: () =>
			import(/* webpackChunkName: "home" */ '@/_srcv2/pages/home/index.vue'),
	},
	{
		path: '/callback',
		name: 'Callback',
		component: () =>
			import(/* webpackChunkName: "callback" */ '@/views/callback/index.vue'),
	},
	{
		path: '/invalid-link',
		name: 'InvalidLink',
		component: () =>
			import(
				/* webpackChunkName: "invalid-link" */ '@/_srcv2/pages/invalid-link/IInvalidLink.vue'
			),
	},
	// todo ------------------------------- $ Accountant routes---------------------------------------
	{
		path: '/accountant/view-sale-invoices',
		name: 'ViewSaleInvoices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "accountant-view-sale-invoice" */ '@/_srcv2/pages/accountant/view-sale-invoices/ViewSaleInvoices.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountantGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ------------------------------- $ Stock routes---------------------------------------
	{
		path: '/stock/add-edit-stock-item',
		name: 'AddEditStockItem',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-edit-stock-item" */ '@/_srcv2/pages/admin/edit-stock-prices/AddEditStockPrices'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getStockGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/stock',
		name: 'Stock',
		meta: { requiresAuth: true },
		component: () =>
			import(/* webpackChunkName: "stock" */ '@/_srcv2/pages/stock/Stock.vue'),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getStockGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/insufficient-stock-items',
		name: 'InsufficientStock',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "stock" */ '@/_srcv2/pages/stock/layouts/insufficient-stock/SearchInsufficientStockItem.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getStockGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** $ Order **************************************
	{
		path: '/order/add-order',
		name: 'AddOrder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-order" */ '@/_srcv2/pages/admin/create-order/CreateOrder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/order/view-order',
		name: 'ViewOrder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "view-order" */ '@/_srcv2/pages/order/view-order/ViewOrder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/order/edit-order',
		name: 'EditOrder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "edit-order" */ '@/_srcv2/pages/order/edit-order/EditOrder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/order/get-order-report',
		name: 'GetOrderReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-order-report" */ '@/_srcv2/pages/order/_shared/get-order-report/GetOrderReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/order/order-report-pdf',
		name: 'OrderReportPdf',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "order-report-pdf" */ '@/_srcv2/pages/order/_shared/get-order-report/OrderReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** $ Dispatch **************************************
	{
		path: '/dispatch',
		name: 'ViewOrdersToDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "dispatch" */ '@/_srcv2/pages/dispatch/add-dispatch/ViewOrdersToDispatch'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getDispatchGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/dispatch/add-dispatch',
		name: 'AddDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-dispatch" */ '@/_srcv2/pages/dispatch/add-dispatch/AddDispatch'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getDispatchGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/dispatch/edit-dispatch',
		name: 'EditDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "edit-dispatch" */ '@/_srcv2/pages/dispatch/edit-dispatch/EditDispatch.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getDispatchGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/dispatch/view-dispatch',
		name: 'ViewDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "view-dispatch" */ '@/_srcv2/pages/dispatch/view-dispatch/ViewDispatch'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getDispatchGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/dispatch/create-return-order-dispatch',
		name: 'CreateReturnOrderDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-return-order-dispatch" */ '@/_srcv2/pages/dispatch/return-dispatch/CreateReturnOrderDispatch.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getOrderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** $ Delivery **************************************
	{
		path: '/delivery/create-new-route-list',
		name: 'CreateNewRouteList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-new-route-list" */ '@/_srcv2/pages/deliveries-route-lists/create-route/CreateNewRouteList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},

	{
		path: '/delivery/add-extract-dispatch-to-route-list',
		name: 'AddExtractDispatchToRouteList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-extract-dispatch-to-route-list" */ '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/AddExtractDispatchToRouteList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},

	{
		path: '/delivery/add-edit-driver',
		name: 'AddEditDriver',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-edit-driver" */ '@/_srcv2/pages/deliveries-route-lists/driver/AddEditDriver.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},

	{
		path: '/delivery/add-edit-vehicle',
		name: 'AddEditVehicle',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-edit-vehicle" */ '@/_srcv2/pages/deliveries-route-lists/vehicle/AddEditVehicle.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},

	// todo **************************** $ Sale Invoice **************************************
	{
		path: '/sale-invoice',
		name: 'ViewDispatchesToInvoice',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "sale-invoice" */ '@/_srcv2/pages/sale-invoice/add-invoice/ViewDispatchesToInvoice.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getSaleInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/sale-invoice/add-sale-invoice',
		name: 'AddInvoice',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-sale-invoice" */ '@/_srcv2/pages/sale-invoice/add-invoice/AddInvoice.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getSaleInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/sale-invoice/view-sale-invoice',
		name: 'ViewInvoices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "view-sale-invoice" */ '@/_srcv2/pages/sale-invoice/view-invoice/ViewInvoices.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getSaleInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/sale-invoice/create-credit-invoice',
		name: 'CreateCreditInvoiceDispatchOrder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-credit-invoice" */ '@/_srcv2/pages/sale-invoice/credit-invoice/CreateCreditInvoiceDispatchOrder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getSaleInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** $ Purchase Invoice **************************************
	{
		path: '/purchase-invoice/add-purchase-invoice',
		name: 'AddPurchaseInvoice',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-purchase-invoice" */ '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/AddPurchaseInvoice.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getPurchaseInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/purchase-invoice/edit-purchase-invoice',
		name: 'EditPurchaseInvoice',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "edit-purchase-invoice" */ '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/EditPurchaseInvoice.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getPurchaseInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/purchase-invoice/purchase-invoice-report',
		name: 'PurchaseInvoiceReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "purchase-invoice-report" */ '@/_srcv2/pages/purchase-invoice/purchase-invoice-report/PurchaseInvoiceReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getPurchaseInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/purchase-invoice/get-purchase-invoices',
		name: 'GetPurchaseInvoices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-purchase-invoices" */ '@/_srcv2/pages/purchase-invoice/_shared/GetPurchaseInvoicesByDateInterval.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getPurchaseInvoiceGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** $ Transaction **************************************
	{
		path: '/transaction/add-transaction',
		name: 'AddTransaction',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-transaction" */ '@/_srcv2/pages/transactions/add-transaction/AddTransaction.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getTransactionsGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? Collect Payment
	{
		path: '/get-data-collect-payment',
		name: 'GetDataCollectPayment',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-data-collect-payment" */ '@/_srcv2/views/pages/accounting/components/collect-payment/GetDataCollectPayment.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getTransactionsGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? ----------------------------------------------------------------------------
	// ? Collect Payment
	{
		path: '/collections-from-customers',
		name: 'CollectionsFromCustomers',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "collections-from-customers" */ '@/_srcv2/pages/transactions/collections-from-customers/CollectionsFromCustomers.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getTransactionsGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? Make Payment
	{
		path: '/payments-to-suppliers',
		name: 'PaymentsToSuppliers',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "payments-to-suppliers" */ '@/_srcv2/pages/transactions/payments-to-suppliers/PaymentsToSuppliers.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getTransactionsGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	//   todo **************************** $ Reminder **************************************
	{
		path: '/reminder/send-reminder',
		name: 'SendReminder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "send-reminder" */ '@/_srcv2/pages/reminder/send-reminder/SendReminder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/get-report-data',
		name: 'GetReminderReportData',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "reminder-get-report-data" */ '@/_srcv2/pages/reminder/report/GetReminderReportData.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/reminder-report',
		name: 'ReminderReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "reminder-report" */ '@/_srcv2/pages/reminder/report/ReminderReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/send-to-inkasso',
		name: 'SendToInkasso',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "send-to-inkasso" */ '@/_srcv2/pages/reminder/send-to-inkasso/SendToInkasso.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/send-to-kronofogden',
		name: 'SendToKronofogden',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "reminder/send-to-kronofogden" */ '@/_srcv2/pages/reminder/sent-to-kronofogden/SendToKronofogden.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/send-to-doubtful-receivables',
		name: 'SendToDoubtfulReceivables',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "reminder/send-to-doubtful-receivables" */ '@/_srcv2/pages/reminder/send-to-doubtful-receivables/SendToDoubtfulReceivables.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/reminder/view',
		name: 'ViewReminder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "view-reminder" */ '@/_srcv2/pages/reminder/view/ViewReminder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getReminderGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ----------------------------$ Customers ---------------------------------------------
	{
		path: '/customer/customer-list',
		name: 'CustomerList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-list" */ '@/_srcv2/views/pages/admin-reports/customer-list/CustomerList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/customer/get-unpaid-invoices',
		name: 'GetUnpaidInvoices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-unpaid-invoices" */ '@/_srcv2/pages/admin/unpaid-invoices/GetUnpaidInvoices.vue'
			),
	},
	{
		path: '/customer/customer-payments',
		name: 'CustomerPayments',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-payments" */ '@/_srcv2/views/pages/accounting/components/customer-payments/CustomerPayments.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getTransactionsGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/customer/special-prices',
		name: 'CustomerSpecialPrices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-price-list" */ '@/views/customer/layouts/customerPriceList/customerPriceListMainLayout'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ******************************** Accounting ***************************************************
	// ? Get collected payments
	{
		path: '/accounting/get-collected-payments',
		name: 'Accounting',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-collected-payments" */ '@/_srcv2/pages/accounting/page-get-collected-payments/GetCollectedPayments.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? Create collected payments report
	{
		path: '/accounting/create-collected-payments-report',
		name: 'CreateCollectedPaymentsReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-collected-payments-report" */ '@/_srcv2/pages/accounting/page-create-collected-payments-report/CreateCollectedPaymentsReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? Get Bookkeeping Report List
	{
		path: '/accounting/bookkeeping-report-list',
		name: 'BookkeepingReportList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "bookkeeping-report-list" */ '@/_srcv2/pages/accounting/reports/bookkeeping-reports/BookkeepingReportList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ? Get collected payments report
	{
		path: '/accounting/get-collected-payments-report',
		name: 'GetCollectedPaymentsReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-collected-payments-report" */ '@/_srcv2/pages/accounting/page-create-collected-payments-report/report/GetCollectedPaymentsReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/accounting/collected-payments-report',
		name: 'CollectedPaymentsReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "collected-payments-report" */ '@/_srcv2/pages/accounting/page-create-collected-payments-report/report/CollectedPaymentsReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// ?  sale-invoice journal
	{
		path: '/accounting/create-sale-invoice-journal',
		name: 'CreateInvoiceJournal',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-sale-invoice-journal" */ '@/_srcv2/pages/accounting/page-create-invoice-journal/CreateInvoiceJournal.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/accounting/sale-invoice-journal-report',
		name: 'InvoiceJournalReport',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "sale-invoice-journal-report" */ '@/_srcv2/pages/accounting/page-create-invoice-journal/report/InvoiceJournalReport.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/accounting/out-land-invoices',
		name: 'OutLandInvoices',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "out-land-invoices" */ '@/_srcv2/pages/accounting/page-outland-invoices/OutLandInvoices.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAccountingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ****************************** Marketing *********************************************
	{
		path: '/marketing/add-new-potential-customer',
		name: 'Marketing',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-new-potential-customer" */ '@/_srcv2/pages/business-development/Marketing.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/potential-customer-route-list', // ***
		name: 'PotentialCustomerRouteList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "potential-customer-route-list" */ '@/_srcv2/pages/business-development/potential-customers-routes/PotentialCustomerRouteList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/daily-visit-list', // ***
		name: 'DailyVisitsList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "daily-visit-list" */ '@/_srcv2/pages/business-development/daily-visits/DailyVisitsList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/daily-alarm-list', // ***
		name: 'DailyAlarmList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "daily-alarm-list" */ '@/_srcv2/pages/business-development/daily-alarms/DailyAlarmsList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/add-customer-info', // ***
		name: 'AddCustomerInfo',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "add-customer-info" */ '@/_srcv2/pages/business-development/customer-info/add-customer-info/AddCustomerInfo.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/get-customer-marketing-info',
		name: 'GetCustomerMarketingInfo',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-customer-marketing-info" */ '@/_srcv2/pages/business-development/customer-info/get-customer-marketing-info/GetCustomerMarketingInfo.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/get-customer-economic-info',
		name: 'GetCustomerEconomicInfo',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-customer-economic-info" */ '@/_srcv2/pages/business-development/customer-info/get-customer-economic-info/GetCustomerEconomicInfo.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/time-interval-months-revenues',
		name: 'GetTimeIntervalCustomersInvoicesStatistics',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "time-interval-months-revenues" */ '@/_srcv2/pages/business-development/time-interval-statistics/GetTimeIntervalCustomersInvoicesStatistics.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/marketing/count-dispatches-by-revenue-centers',
		name: 'GetCountDispatchesByRevenueCenters',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "count-dispatches-by-revenue-centers" */ '@/_srcv2/pages/business-development/statistics-pages/revenue-center-statistics/GetCountDispatchesByRevenueCenters.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getMarketingGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ****************************** Delivery *********************************************
	// {
	// 	path: '/delivery/add-edit-driver',
	// 	name: 'AddEditDriver',
	// 	meta: { requiresAuth: true },
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "add-edit-driver" */ '@/_srcv2/pages/deliveries-route-lists/driver/AddEditDriver.vue'
	// 		),
	// 	beforeEnter: (to, from, next) => {
	// 		if (Store.getters.getAdminGuard) {
	// 			next()
	// 		} else {
	// 			next('/invalid-link')
	// 		}
	// 	},
	// },
	// todo **************************** Admin **************************************
	{
		path: '/admin/report-time-interval-revenue',
		name: 'TimeIntervalRevenue',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "report-time-interval-revenue" */ '@/_srcv2/views/pages/admin-reports/time-interval-revenue/TimeIntervalRevenue.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/incomes-by-route-id', // ****
		name: 'IncomesByRouteId',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-list" */ '@/_srcv2/pages/admin/incomes-by-routes/IncomesByRouteId.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/invoices-to-be-paid', // ****
		name: 'InvoicesToBePaid',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "invoices-to-be-paid" */ '@/_srcv2/pages/admin/invoices-to-be-paid/InvoicesToBePaid.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/change-invoice-due-date', // *****
		name: 'ChangeInvoiceDueDate',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "change-invoice-due-date" */ '@/_srcv2/pages/admin/super-privileges/change-invoice-due-date/ChangeInvoiceDueDate.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/change-reminder-due-date', // *****
		name: 'ChangeReminderDueDate',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "change-reminder-due-date" */ '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/ChangeReminderDueDate.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getDevGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/create-order',
		name: 'CreateOrder',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-order" */ '@/_srcv2/pages/admin/create-order/CreateOrder.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/create-order-dispatch',
		name: 'CreateOrderDispatch',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-order-dispatch" */ '@/_srcv2/pages/admin/create-order-dispatch/CreateOrderDispatch.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/create-order-dispatch-invoice',
		name: 'CreateOrderDispatchInvoice',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "create-order-dispatch-invoice" */ '@/_srcv2/pages/admin/create-order-dispatch-invoice/CreateOrderDispatchInvoice.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/admin/set-pass-key',
		name: 'SetPrivilegePass',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "set-pass-key" */ '@/_srcv2/composables/admin/SetPrivilegePass.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo **************************** Utilities **************************************
	{
		path: '/utilities/search-document',
		name: 'SearchDocument',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "search-document" */ '@/_srcv2/components/_shared/search-document/SearchDocumentByDocumentCrossNumbers.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getUtilitiesGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/utilities/customer-route-list', // ***
		name: 'CustomerRouteList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-route-list" */ '@/_srcv2/views/pages/admin-reports/customer-route-list/CustomerRouteList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getUtilitiesGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/utilities/report-time-interval-customer-consumption',
		name: 'TimeIntervalCustomerConsumption',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "report-time-interval-customer-consumption" */ '@/_srcv2/views/pages/admin-reports/time-interval-customer-consumption/TimeIntervalCustomerConsumption.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/utilities/customer-history',
		name: 'CustomerHistory',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customer-history" */ '@/_srcv2/pages/admin/customer-history/CustomerHistory.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getUtilitiesGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/utilities/price-list', // *****
		name: 'PriceList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "price-list" */ '@/_srcv2/pages/_utilities/price-list/PriceListGeneral.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getUtilitiesGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/utilities/customers-price-list', // ***
		name: 'CustomerPriceList',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "customers-price-list" */ '@/_srcv2/pages/_utilities/price-list/CustomersPriceList.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getUtilitiesGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	// todo ************************** Startup ***********************************************************
	{
		path: '/startup',
		name: 'Startup',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "startup" */ '@/_srcv2/views/pages/startup/layout/StartupNewYear'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/set-outstanding-values',
		name: 'SetOutstandingValues',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "set-outstanding-values" */ '@/_srcv2/views/pages/startup/components/set-outstanding-values/SetOutstandingValues.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/set-inventory-variables',
		name: 'SetInventoryVariables',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "set-inventory-variables" */ '@/_srcv2/views/pages/startup/components/set-inventory-values/SetInventoryVariables'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/get-inventory-values',
		name: 'GetInventoryValues',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "get-inventory-values" */ '@/_srcv2/views/pages/startup/components/set-inventory-values/GetInventoryValues'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/inventory-report-detailed',
		name: 'InventoryReportDetailed',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "inventory-report-detailed" */ '@/_srcv2/views/pages/startup/report/InventoryReportDetailed'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/set-credit-debit-values-from-inventory',
		name: 'SetCreditDebitValuesFromInventory',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "set-credit-debit-values-from-inventory" */ '@/_srcv2/views/pages/startup/components/set-credit-debit/SetCreditDebitValuesFromInventory.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
	{
		path: '/startup/set-outstanding-values-from-inventory',
		name: 'SetOutstandingValuesFromInventory',
		meta: { requiresAuth: true },
		component: () =>
			import(
				/* webpackChunkName: "set-outstanding-values-from-inventory" */ '@/_srcv2/views/pages/startup/components/set-outstanding-values/SetOutstandingValuesFromInventory.vue'
			),
		beforeEnter: (to, from, next) => {
			if (Store.getters.getAdminGuard) {
				next()
			} else {
				next('/invalid-link')
			}
		},
	},
]
// todo ---------------------------------------------------------------------------------
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})
// todo ---------------------------------------------------------------------------------
router.beforeEach((to, from, next) => {
	const loggedIn = lsGetLogInfo('li') || false
	if (loggedIn && to.name === 'Login') {
		next('/')
	} else if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		!loggedIn
	) {
		next('/login')
	}
	next()
})
// todo ---------------------------------------------------------------------------------

export default router
