/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import i18n from './plugins/i18n/i18n' // Locales
import VueCompositionApi from '@vue/composition-api' // composition api
// import '@babel/polyfill'
import './components/globals/_globals' // Globally register all `_base`-prefixed components
import AuthPlugin from './plugins/auth/auth' // import AuthService from './plugins/auth/authService'
import './layouts' // Layouts
import UUID from 'vue-uuid' // UUID
// vue-form-generator
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
// Apollo
import { provide } from '@vue/composition-api'
// import { ApolloClients } from '@vue/apollo-composable'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
// Main CSS
import './main.scss'

// cloudinary
import Cloudinary from 'cloudinary-vue'

// lsstore
import { lsGetTokenInfo } from '../src/plugins/lsstore/index'
// Vue Good Table
import VueGoodTablePlugin from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin)
Vue.config.productionTip = false

// CSV
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)
// Apex Charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
// get headers
const getHeaders = () => {
	const headers = {}
	// const token = window.localStorage.getItem('apollo-token')
	const token = lsGetTokenInfo()
	if (token) {
		headers.authorization = `Bearer ${token}`
	}
	console.log('from main.js headers', headers)
	return headers
}

let link
if (process.env.VUE_APP_APOLLO_LINK_PROTOCOL === 'wss') {
	// ** WSS connection to the API
	link = new WebSocketLink({
		uri: process.env.VUE_APP_GRAPHQL_BACKEND_URL_WSS, // 'wss://gtbs-crm-backend-app.herokuapp.com/v1/graphql',
		options: {
			reconnect: true,
			timeout: 30000,
			connectionParams: () => {
				return { headers: getHeaders() }
			},
		},
	})
} else {
	link = createHttpLink({
		uri: process.env.VUE_APP_GRAPHQL_BACKEND_URL_HTTPS, // 'https://gtbs-crm-backend-app.herokuapp.com/v1/graphql',
		options: {
			reconnect: true,
			timeout: 30000,
			connectionParams: () => {
				return { headers: getHeaders() }
			},
		},
	})
}
// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
	link: link,
	cache,
})
Vue.use(AuthPlugin)
Vue.use(Buefy)
Vue.use(VueFormGenerator, {
	validators: {
		positivenumber: (value) => {
			if (value < 1) {
				return ['The value must be greater then 0']
			} else {
				return []
			}
		},
		negativenumber: (value) => {
			if (value > -1) {
				return ['The value must be negative number']
			} else {
				return []
			}
		},
		notnegativenumber: (value) => {
			if (value < 0) {
				return ['The value must be positive number']
			} else {
				return []
			}
		},
		notzero: (value) => {
			if (value === 0) {
				return ['The value must not be zero']
			} else {
				return []
			}
		},
		notempty: (value) => {
			if (value === '') {
				return ['The value must not be ""']
			} else {
				return []
			}
		},
		notnull: (value) => {
			if (value.length < 1 || null || undefined) {
				return ['Nothing selected!!!']
			} else {
				return []
			}
		},
	},
})
Vue.use(VueCompositionApi)
Vue.use(UUID)
Vue.use(Cloudinary, {
	configuration: {
		cloudName: 'gtbs-coding',
		api_key: '179898519535187',
		api_secret: '2rgHp4WCrcQ2_q14M9vwl4_F6Bs',
	},
})

new Vue({
	setup() {
		provide(DefaultApolloClient, apolloClient)
	},
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
