<!-- @format -->

<template>
	<div class="App">
		<router-view />
	</div>
</template>

<script>
import Router from '@/router'

export default {
	async created() {
		try {
			await this.$auth.renewTokens()
		} catch {
			// Supress the 'not logged in' error as we can illegitimately get that
			// when processing the callback url
		}
	},
	mounted() {
		if (localStorage.getItem('localei18n') === undefined) {
			localStorage.setItem('localei18n', 'en')
		}
		let keysPressed = {}
		document.addEventListener('keydown', (event) => {
			keysPressed[event.key] = true
			if (keysPressed['Escape'] && event.key === 'i') {
				console.log('keysPressed', keysPressed)
				Router.push({ name: 'ViewDispatchesToInvoice' })
			} else if (keysPressed['Escape'] && event.key === 'd') {
				Router.push({ name: 'ViewOrdersToDispatch' })
			} else if (keysPressed['Escape'] && event.key === 'o') {
				Router.push({ name: 'AddOrder' })
			} else if (keysPressed['Escape'] && event.key === 'p') {
				Router.push({ name: 'AddPurchaseInvoice' })
			} else if (keysPressed['Escape'] && event.key === 'a') {
				Router.push({ name: 'TimeIntervalRevenue' })
			} else if (keysPressed['Escape'] && event.key === 's') {
				Router.push({ name: 'AddEditStockItem' })
			} else if (keysPressed['Escape'] && event.key === 't') {
				Router.push({ name: 'AddTransaction' })
			} else if (keysPressed['Escape'] && event.key === 'm') {
				Router.push({ name: 'Marketing' })
			} else if (keysPressed['Escape'] && event.key === 'r') {
				Router.push({ name: 'SendReminder' })
			} else if (keysPressed['Escape'] && event.key === 'c') {
				Router.push({ name: 'CustomerList' })
			} else if (keysPressed['Escape'] && event.key === 'u') {
				Router.push({ name: 'SearchDocument' })
			} else if (keysPressed['Escape'] && event.key === 'b') {
				Router.push({ name: 'Accounting' })
			}
		})

		document.addEventListener('keyup', (event) => {
			delete keysPressed[event.key]
		})
	},
}
</script>
<style lang="scss">
@import '../node_modules/vue-good-table/src/styles/style.scss';
/*append -----------------------------------------------------------------*/
/* mouse over link */
a:hover {
	color: hotpink;
}

/*append end-----------------------------------------------------------------*/
p {
	margin-bottom: 10px;
}
/*set alignment-----------------------------------------------------------------*/
.center-it {
	margin: auto;
}
.text-align-center {
	text-align: center;
}
.text-align-right {
	text-align: end;
}
.text-align-left {
	text-align: start;
}
/* margin left----------------------------------------------------------------*/
.margin-left-5 {
	margin-left: 15px;
}
.margin-left-10 {
	margin-left: 10px;
}
.margin-left-15 {
	margin-left: 15px;
}
.margin-left-20 {
	margin-left: 20px;
}
.margin-left-30 {
	margin-left: 30px;
}
/* margin top----------------------------------------------------------------*/
.margin-top-5 {
	margin-top: 15px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.margin-top-20 {
	margin-top: 20px;
}
.margin-top-30 {
	margin-top: 30px;
}
/* margin right----------------------------------------------------------------*/
.margin-right-5 {
	margin-right: 15px;
}
.margin-right-10 {
	margin-right: 10px;
}
.margin-right-15 {
	margin-right: 15px;
}
.margin-right-20 {
	margin-right: 20px;
}
/* margin bottom----------------------------------------------------------------*/
.margin-bottom-5 {
	margin-bottom: 15px;
}
.margin-bottom-10 {
	margin-bottom: 10px;
}
.margin-bottom-15 {
	margin-bottom: 15px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.margin-bottom-30 {
	margin-bottom: 30px;
}
/* Text colors----------------------------------------------------------------*/
.text-red {
	color: red;
}
.text-green {
	color: green;
}
.text-blue {
	color: blue;
}
.text-purple {
	color: purple;
}
/* Text weigh----------------------------------------------------------------*/
.text-bold {
	font-weight: bold;
}
.text-bolder {
	font-weight: bolder;
}

/* Form elements----------------------------------------------------------------*/
.form-title {
	font-weight: bolder;
}
.form-alert {
	font-weight: bold;
	color: red;
}
.form-info {
	font-size: 1.2em;
	font-weight: bold;
	color: #209cee;
	margin: 0 0.5rem;
	animation: flash; /* referring directly to the animation's @keyframe declaration */
	animation-duration: 2s; /* don't forget to set a duration! */
}
.table-header {
	color: white;
	padding: 20px;
	font-size: x-large;
	font-weight: 500;
	margin-bottom: -10px;
}
.table-title {
	color: white;
	padding-left: 20px;
	padding-top: 20px;
	font-size: x-large;
	font-weight: 500;
	margin-bottom: -10px;
}
.table-sub-title {
	color: white;
	padding: 20px;
	font-size: large;
	font-weight: 500;
	margin-bottom: -10px;
}
.table-color {
	background-color: rgb(76, 96, 122);
	min-height: 65px;
	color: white;
	padding: 20px;
	font-size: larger;
	margin-bottom: -10px;
	margin-top: 30px;
}
/* Elements width ----------------------------------------------------------------*/
.width-100 {
	width: 100px;
}
.width-150 {
	width: 150px;
}
.width-180 {
	width: 180px;
}
.width-200 {
	width: 200px;
}
.width-250 {
	width: 250px;
}
.width-300 {
	width: 300px;
}
.width-350 {
	width: 350px;
}
.width-400 {
	width: 400px;
}
.width-450 {
	width: 450px;
}
.width-500 {
	width: 500px;
}
.disable-div {
	pointer-events: none;
}
.enable-div {
	pointer-events: auto;
}
</style>
