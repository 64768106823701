<template>
	<div>
		<!-- Two-way Data-Binding -->
		<quill-editor
			ref="Quill Editor"
			v-model="content"
			:options="editorOption"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"
		/>
		<section>
			<b-button
				class="margin-top-10 width-250"
				style="background-color: rgb(72, 199, 142); color: white"
				@click="setStore()"
			>
				Confirm the changes
			</b-button>
		</section>
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// Quill Editor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// Quill Editor End

export default {
	name: 'Editor',
	components: { quillEditor },
	props: {
		editorContent: {
			type: String,
			default: 'Nothing to show',
		},
		storeContent: {
			type: Function,
			default: () => {},
		},
	},
	setup(props) {
		let content = ref('')
		const editorOption = {
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'blockquote', 'clean'],
					[{ header: 1 }, { header: 2 }, { header: [1, 2, 3, 4, 5, 6, false] }],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ color: [] }, { background: [] }],
					['link', 'video'],
				],
			},
		}
		const onEditorBlur = () => {
			setStore()
		}
		// const onEditorBlur = quill => console.log('editor blur!', quill)
		const onEditorFocus = (quill) => console.log('editor focus!', quill)
		const onEditorReady = (quill) => console.log('editor ready!', quill)
		// const editor = computed(() => vm.$refs.myQuillEditor.quill)
		onMounted(() => {
			console.log('Editor mounted')
		})
		const setStore = () => {
			props.storeContent(content.value)
		}
		return {
			content,
			editorOption,
			// editor,
			onEditorReady,
			onEditorFocus,
			onEditorBlur,
			quillEditor,
			setStore,
		}
	},
}
</script>
<style>
.margin-top-15 {
	margin-top: 15px;
}
</style>
