<!-- @format -->

<template>
	<div>
		<div class="margin-bottom-10 text-bold">
			<slot name="second" />
		</div>
		<vue-good-table
			:columns="$props.columns"
			:rows="$props.rows"
			@on-row-dblclick="$props.onRowDoubleClick"
			@on-cell-click="$props.onCellClick"
			styleClass="vgt-table striped bordered"
			:theme="$props.theme"
			:max-height="$props.maxHeight"
			:fixed-header="true"
			:search-options="{ enabled: $props.searchedOptions }"
			:sort-options="$props.sortOptions"
			:pagination-options="$props.paginationOptions"
		>
			<template slot="table-row" slot-scope="props">
				<span style="margin-left: auto" v-if="props.column.field === 'select'">
					<b-button class="is-small is-success">Select</b-button>
				</span>
				<span style="margin-left: auto" v-if="props.column.field === 'preview'">
					<b-button class="is-small is-success">Preview</b-button>
				</span>
				<span
					style="margin-left: auto"
					v-if="props.column.field === 'unlock' && props.row.isLocked"
				>
					<b-button class="is-small is-danger">Unlock</b-button>
				</span>
				<span
					style="margin-left: auto"
					v-if="props.column.field === 'unlock' && !props.row.isLocked"
				>
					<b-button class="is-small is-success">Edit</b-button>
				</span>
				<span
					style="margin-left: auto"
					v-else-if="props.column.field === 'reminderNo'"
				>
					<b-button class="is-small is-success text-bold text-9xl">{{
						props.row.reminderNo
					}}</b-button>
				</span>
				<!--        Current Purchase Invoice-->
				<span v-else-if="props.column.field === 'amount_debit'">
					<p class="text-bold text-purple text-align-center">
						{{ props.row.amount_debit }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'vat_debit_exchange'">
					<p class="text-bold text-green text-align-right">
						{{ formatTheNumber(props.row.vat_debit_exchange) }}
					</p>
				</span>
				<span
					v-else-if="props.column.field === 'line_price_total_debit_exchange'"
				>
					<p class="text-bold text-align-right">
						{{ formatTheNumber(props.row.line_price_total_debit_exchange) }}
					</p>
				</span>
				<!--        Current Order-->
				<span v-else-if="props.column.field === 'delete'">
					<b-button class="is-small is-success"> Delete </b-button>
				</span>
				<span v-else-if="props.column.field === 'invoice_price_exchange'">
					<p class="text-bold text-blue text-align-right">
						{{ formatTheNumber(props.row.invoice_price_exchange) }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'invoicePriceExchange'">
					<p class="text-bold text-blue text-align-right">
						{{ formatTheNumber(props.row.invoicePriceExchange) }}
					</p>
				</span>
				<span
					v-else-if="props.column.field === 'line_price_total_credit_exchange'"
				>
					<p class="text-bold text-green text-align-right">
						{{ formatTheNumber(props.row.line_price_total_credit_exchange) }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'linePriceTotalCreditExchange'">
					<p class="text-bold text-green text-align-right">
						{{ formatTheNumber(props.row.linePriceTotalCreditExchange) }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'amount_credit'">
					<p class="text-bold text-red text-align-center">
						{{ props.row.amount_credit }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'amountCredit'">
					<p class="text-bold text-red text-align-center">
						{{ props.row.amountCredit }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'amount'">
					<p class="text-bold text-red text-align-center">
						{{ props.row.amount }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'reminderDueDate'">
					<span class="text-bold text-purple">
						{{ props.row.reminderDueDate }}
					</span>
				</span>
				<span v-else-if="props.column.field === 'invoiceBalance'">
					<p class="text-bold text-red text-align-right">
						{{ props.row.invoiceBalance }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'invoiceNumber'">
					<p
						class="text-bold text-align-right ve-clickable is-clickable"
						style="text-decoration: underline"
					>
						{{ props.row.invoiceNumber }}
					</p>
				</span>
				<span v-else-if="props.column.field === 'reminderId'">
					<p class="text-bold has-text-link text-align-right">
						<a href="url" style="text-decoration-line: underline">
							{{ props.row.reminderId }}
						</a>
					</p>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
import numeral from 'numeral'
export default {
	name: 'VeTable',
	props: {
		columns: Array,
		rows: Array,
		onRowDoubleClick: {
			default: () => {},
			type: Function,
		},
		onCellClick: {
			default: () => {},
			type: Function,
		},
		theme: {
			default: 'black-rhino',
			type: String,
		},
		maxHeight: {
			default: '700px',
			type: String,
		},
		searchedOptions: {
			default: true,
			type: Boolean,
		},
		sortOptions: {
			type: Object,
		},
		paginationOptions: {
			default: () => {
				return {
					enabled: true,
					mode: 'records',
					perPage: [5, 10, 15, 20],
					position: 'bottom',
					perPageDropdown: 10,
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}
			},
			type: Object,
		},
		perPageDropdown: {
			default: () => [5, 10, 15, 20],
			type: Array,
		},
		perPage: {
			default: () => 10,
			type: Number,
		},
	},
	setup() {
		numeral.defaultFormat('0,0.00')
		const formatTheNumber = (number) => {
			return numeral(number / 100).format()
		}
		return {
			formatTheNumber,
		}
	},
}
</script>

<style scoped>
.ve-clickable {
	color: blue;
}
.ve-clickable:hover {
	color: red;
}
</style>
